<template>
  <v-app-bar
    class="de-app-bar"
    app
    clipped-left
    flat
    color="white"
    :mini-variant.sync="minisider"
    v-resize="onResize"
  >
    <v-app-bar-nav-icon class="d-none" @click.stop="setMinisider(!minisider)">
    </v-app-bar-nav-icon>

    <a href="/">
      <v-img
        :src="require('@/assets/de-imgs/cd_logo.svg')"
        max-height="64"
        contain
        position="left center"
      />
    </a>

    <v-spacer></v-spacer>

    <app-bar-menu-tools></app-bar-menu-tools>

    <app-bar-menu-locale></app-bar-menu-locale>

    <app-bar-menu-user></app-bar-menu-user>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CoreAppBar",
  components: {
    AppBarMenuUser: () => import("@/components/core/AppBarMenuUser"),
    AppBarMenuLocale: () => import("@/components/core/AppBarMenuLocale"),
    AppBarMenuTools: () => import("@/components/core/AppBarMenuTools"),
  },
  data: () => ({
    windowSize: window.innerWidth,
  }),
  computed: mapState({
    minisider: (state) => state.drawerSidebarModule.minisider,
  }),
  methods: {
    ...mapActions("drawerSidebarModule", ["setMinisider"]),
    onResize() {
      this.windowSize = window.innerWidth;
      if (this.windowSize <= 960) {
        this.setMinisider(true);
      }
    },
  },
  mounted() {
    this.onResize();
  },
};
</script>

<style scoped>
.de-app-bar {
  border-bottom: 1px solid #dddddd !important;
}
</style>
